<!--  -->
<template>
  <div class="container">
    <el-dialog
      v-if="!isPeople"
      v-dialogDrag
      title="选择人员"
      :append-to-body="true"
      :visible.sync="personVisible"
      width="630px"
      :before-close="
        () => {
          beforeClose();
        }
      "
      :close-on-click-modal="false"
    >
      <div class="common-chooseItem-container">
        <div
          v-for="(item, index) in checkedList"
          :key="index"
          class="common-choose-item"
        >
          <span>{{ item.name }}</span
          ><i
            v-if="
              type == 'one' &&
                (item.name == '公司管理员' || item.name == '超级管理员')"
          ></i>

          <i
            v-else
            @click="
              () => {
                deletePerson(item);
              }
            "
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <br /><br />
      <div class="common-person-container">
        <div class="person-title">
          <div class="left-text">组织成员</div>
          <!-- <div class="right-seach">
            <el-input
              class="seach-input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="(val) => seachFun(val)"
              v-model="seachInfo"
            ></el-input>
          </div> -->
        </div>
        <div class="person-content">
          <div class="content-left">
            &nbsp;角色
          </div>
          <div class="content-right">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <!-- 复选框 -->
            <div v-for="(item, index) in roleData" :key="index" class="checkone">
              <el-checkbox
                v-model="item.checked"
                :disabled="type=='one'&&(item.name == '公司管理员'||item.name == '超级管理员')"
                @change="
                  (val) => {
                    changePerson(val, item);
                  }"
                >{{ item.name }}</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              beforeClose();
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              saveRole();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>


    <el-dialog
      v-else
      v-dialogDrag
      title="选择人员"
      :append-to-body="true"
      :visible.sync="personVisible"
      width="630px"
      :before-close="
        () => {
          beforeClose();
        }
      "
      :close-on-click-modal="false"
    >
      <div class="common-chooseItem-container">
        <div
          v-for="(item, index) in checkedList"
          :key="index"
          class="common-choose-item"
        >
          <span>{{ item.name }}</span
          ><i
            @click="
              () => {
                deletePerson(item);
              }
            "
            class="el-icon-circle-close"
          ></i>
        </div>
      </div>
      <br /><br />
      <div class="common-person-container">
        <div class="person-title">
          <div class="left-text">组织成员</div>
          <div class="right-seach">
            <el-input
              class="seach-input"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              @change="(val) => seachFun(val)"
              v-model="seachInfo"
            ></el-input>
          </div>
        </div>
        <div class="person-content">
          <div class="content-left">
            <el-tree
              :data="roleData"
              :props="defaultProps"
              @node-click="
                (val) => {
                  departTreeFun(val);
                }
              "
            ></el-tree>
          </div>
          <div class="content-right">
            <div style="display: flex;flex-direction: column;">
              <el-radio 
              v-for="(item, index) in personData" :key="index"
                v-model="radio" 
                @change="
                  (val) => {
                    changeRadioPerson(val);
                  }"
                :label="item.id"
                class="peopleradio"
                >
                {{ item.nickName }}
              </el-radio>
              <!-- <el-checkbox
                v-model="item.checked"
                @change="
                  (val) => {
                    changePerson(val, item);
                  }
                "
                >{{ item.nickName }}</el-checkbox
              > -->
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          class="common-screen-btn"
          @click="
            () => {
              beforeClose();
            }
          "
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              saveRole();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { Config } from "../../../utils/index.js"
import { personList,commonAllRole } from "../../../service/common.js";

export default {
  name: "",
  components: {},
  props: ["personVisible", "oneRole","isPeople"], //isPeople指定人员
  data() {
    return {
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      checked: false,
      checkedList: [],
      seachInfo: "",
      roleData: [], //角色列表
      defaultProps: {
        children: "child",
        label: "name"
      },
      personData: [],
      type: "", //抄送人
      // roleId: this.showRoleId
      checkAll: false,
      isIndeterminate: false,
      checkedCount: 0,
      peopleArr: [], //指定人员
      radio: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.commonAllRole();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    showRole(str, type, people) {
      this.type = type;

      if (type == "one") {
        if (str) {//有sendId
          let arr = str.split(",");
          arr.forEach((item) => {
            this.roleData.forEach((item2) => {
              if (
                item == item2.id &&
                (item2.name != "公司管理员" || item2.name != "超级管理员")
              ) {
                item2.checked = true;
                this.checkedList.push({ id: item2.id, name: item2.name });
              }
            });
          });
        } else { // 默认管理员
          if (!people) {
            this.roleData.forEach((item2) => {
            if (item2.name == "公司管理员" || item2.name == "超级管理员") {
              item2.checked = true;
              this.checkedList.push({ id: item2.id, name: item2.name });
            }
             });
          }
        }
      }else if (str) {
        let arr = str.split(",");
        if(!people){
          arr.forEach((item) => {
            this.roleData.forEach((item2) => {
              if (item == item2.id&&(item2.name != "公司管理员" || item2.name != "超级管理员")) {
                item2.checked = true;
                this.checkedList.push({ id: item2.id, name: item2.name });
              }
            });
          });
        }else { //指定人员回显
          this.checkedList = JSON.parse(people) 
        }
      }
    },
    async commonAllRole() {
      this.checkedList = [];
      // 角色列表
      let { data } = await commonAllRole({ companyId: -1 });
      data.forEach((element) => {
        if (this.oneRole == "one") {
          if (element.name == "公司管理员" || element.name == "超级管理员") {
            element.checked = true;
            this.checkedList.push({ id: element.id, name: element.name });
          } else {
            element.checked = false;
          }
        } else {
          element.checked = false;
        }
      });
      this.roleData = data;
    },
    // 单选
    changePerson(val, row) {
      if(val){
        this.checkedCount++
      }else{
        this.checkedCount--
      }
       this.checkAll = this.checkedCount === this.roleData.length;
       this.isIndeterminate = this.checkedCount > 0 && this.checkedCount < this.roleData.length;

      // 勾选
      let checkedList = [...this.checkedList];
      if (val) {
        checkedList.push({ id: row.id, name: row.name });
      } else {
        checkedList = checkedList.filter((item) => {
          return row.id != item.id;
        });
      }
      this.checkedList = checkedList;
    },
    deletePerson(item) {
      //删除
      this.checkedList = this.checkedList.filter((item2) => {
        return item2.id != item.id;
      });
      this.roleData.forEach((item3) => {
        if (item3.id == item.id) {
          item3.checked = false;
        }
      });
      if(this.isPeople){ //指定人员
        // this.personData.forEach((item3) => {
        //   if (item3.id == item.id) {
        //     item3.checked = false;
        //   }
        // });
        this.radio = ""
      }
      this.checkedCount = this.checkedList.length
      this.checkAll = false
      this.isIndeterminate = this.checkedCount > 0 && this.checkedCount < this.roleData.length;
    },
    beforeClose() {
      this.$emit("roleClose");
      this.checkedList = [];
      this.arr = []
      this.type = ""
      this.radio = ""
      this.checkAll = false
      this.isIndeterminate = false
      this.checkedCount = 0
      this.roleData.forEach((item3) => {
        item3.checked = false;
      });
      this.personData = []
    },
    saveRole() {
      this.$emit("colletRoleId", this.checkedList);
      this.beforeClose();
    },
    // 全选
    handleCheckAllChange(val) {
        this.checkedList = val ? this.roleData : [];
        if(val){
          this.checkedList.forEach(item => {
            item.checked = true
          })
          this.checkedCount = this.roleData.length
        }else{
          this.roleData.forEach(item => {
            item.checked = false
          })
          this.checkedCount = 0;
        }
        this.isIndeterminate = false;
    },
    departTreeFun(val) {
      this.seachInfo = ""
      //部门选择
      let params = {
        companyId: this.companyId,
        roleId: val.id,
        pageNum: 1,
        pageSize: 999999
      };
      this.personList(params);
    },
    async personList(params) {
      // 获取人员
      let { data } = await personList(params);
      let checkedList = [...this.checkedList];
      if (data.records && data.records.length) {
        data.records.map((item) => {
          // item.checked = false;
          item.name = item.nickName
          checkedList.map((itemTwo) => {
            if (item.id == itemTwo.id) {
              // item.checked = true;
              this.radio = itemTwo.id
            }
          });
        });
      }
      this.personData = data.records || [];
    },
    seachFun(val) {
      // 搜索人员
      let params = {
        companyId: this.companyId,
        name: val,
        pageNum: 1,
        pageSize: 999999
      };
      this.personList(params);
    },
    changeRadioPerson(val){
      let name = ""
      this.personData.forEach((item)=>{
        if(item.id == val){
          name = item.name
        }
      })
      this.checkedList = [{id: val,name}]
    }
  }
};
</script>

<style lang="less" scoped>
  /deep/ .checkone{
    padding-left: 24px !important;
  }
  /deep/ .peopleradio{
    margin-bottom: 5px;
  }
</style>
